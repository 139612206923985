import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import avatar from '../images/about/avatar.png';
import devdesign from '../images/about/devdesign.png';

const About = () => (
  <Layout>
    <SEO title="Sobre" />
    <article className="about">
      <section className="about__block company">
        <img className="company__image" src={devdesign} alt="App design and development"/>

        <div className="company__text">
          <h3 className="company__title">Desenvolvemos e desenhamos experiências para mobile que facilitam a vida das pessoas.</h3>
          <div className="company__description">
            <p>Com quase 10 anos de experiência trabalhando em projetos de desenvolvimento e design para mobile,
              temos a bagagem necessária para construir ou evoluir o seu projeto, desde o rascunho até a publicação na loja.</p>

            <p>Sem firulas ou buzzwords, só muito trabalho,
              um bom design de soluções e validação do que comprovadamente funciona: esse é o nosso jeito de trabalhar.</p>
        </div>
        </div>
      </section>

      <section className="about__block block founder">
        <img src={avatar} className="founder__image" alt="Walmyr Carvalho"/>

        <div className="founder__text">
          <h3 className="founder__title">Olá, eu sou o Walmyr Carvalho, fundador da Kusudama! :)</h3>
          <div className="founder__description">
            <p>Sou designer, desenvolvedor e trabalho com mobile há 9 anos! Já ajudei empresas como Cielo,
              Globo.com, 99 e Loggi a criarem experiências de qualidade para mobile e
              vi que poderia levar esse objetivo muito adiante.</p>

            <p>Quer me conhecer mais? <a aria-label="Twitter do Walmyr" className="founder__link" href="https://twitter.com/walmyrcarvalho" rel="noopener noreferrer" target="_blank">Olha aqui</a>!</p>  
          </div>
        </div>
      </section>
    </article>
  </Layout>
);

export default About;